#search {
    display: grid;
    grid-area: search;
    grid-template:
        "search" 60px
        / 420px;
    justify-content: center;
    align-content: center;
    justify-items: stretch;
    align-items: stretch;
    // background: hsl(0, 0%, 99%);
    margin-bottom: 30px;

    @media screen and (max-width: 500px) {
        transform: scale(0.8);
    }

    @media screen and (max-width: 400px) {
        transform: scale(0.7);
    }

    @media screen and (max-width: 330px) {
        transform: scale(0.6);
    }
}

.search_input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    margin-left: 14px;
    background-image: url('../../content/close.png');
    cursor: pointer;
}

.search_input::placeholder {
    font-size: 18px;
    color: #444444;
}

#search input {
    font-size: 28px;
    font-family: 'Roboto Mono';
    font-weight: 300;
    display: block;
    grid-area: search;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    border-radius: 100px;
    font: 24px/1 system-ui, sans-serif;
    outline-offset: -8px;
}

#search input:focus {
    outline-style: none;
}

#search svg {
    grid-area: search;
    overflow: visible;
    color: #444;
    fill: none;
    stroke: currentColor;
}

.spark {
    fill: currentColor;
    stroke: none;
    q: 15;
}

.spark:nth-child(1) {
    animation:
        spark-radius 2.03s 1s both,
        spark-one-motion 2s 1s both;
}

@keyframes spark-radius {
    0% {
        r: 0;
        animation-timing-function: cubic-bezier(0, 0.3, 0, 1.57)
    }

    30% {
        r: 15;
        animation-timing-function: cubic-bezier(1, -0.39, 0.68, 1.04)
    }

    95% {
        r: 8
    }

    99% {
        r: 10
    }

    99.99% {
        r: 7
    }

    100% {
        r: 0
    }
}

@keyframes spark-one-motion {
    0% {
        transform: translate(-20%, 50%);
        animation-timing-function: cubic-bezier(0.63, 0.88, 0, 1.25)
    }

    20% {
        transform: rotate(-0deg) translate(0%, -50%);
        animation-timing-function: ease-in
    }

    80% {
        transform: rotate(-230deg) translateX(-20%) rotate(-100deg) translateX(15%);
        animation-timing-function: linear
    }

    100% {
        transform: rotate(-360deg) translate(30px, 100%);
        animation-timing-function: cubic-bezier(.64, .66, 0, .51)
    }
}

.spark:nth-child(2) {
    animation:
        spark-radius 2.03s 1s both,
        spark-two-motion 2.03s 1s both;
}

@keyframes spark-two-motion {
    0% {

        transform: translate(120%, 50%) rotate(-70deg) translateY(0%);
        animation-timing-function: cubic-bezier(0.36, 0.18, 0.94, 0.55)
    }

    20% {

        transform: translate(90%, -80%) rotate(60deg) translateY(-80%);
        animation-timing-function: cubic-bezier(0.16, 0.77, 1, 0.4)
    }

    40% {

        transform: translate(110%, -50%) rotate(-30deg) translateY(-120%);
        animation-timing-function: linear
    }

    70% {

        transform: translate(100%, -50%) rotate(120deg) translateY(-100%);
        animation-timing-function: linear
    }

    80% {

        transform: translate(95%, 50%) rotate(80deg) translateY(-150%);
        animation-timing-function: cubic-bezier(.64, .66, 0, .51)
    }

    100% {

        transform: translate(100%, 50%) rotate(120deg) translateY(0%)
    }
}

.spark:nth-child(3) {
    animation:
        spark-radius 2.05s 1s both,
        spark-three-motion 2.03s 1s both;
}

@keyframes spark-three-motion {
    0% {
        transform: translate(50%, 100%) rotate(-40deg) translateX(0%);
        animation-timing-function: cubic-bezier(0.62, 0.56, 1, 0.54)
    }

    30% {
        transform: translate(40%, 70%) rotate(20deg) translateX(20%);
        animation-timing-function: cubic-bezier(0, 0.21, 0.88, 0.46)
    }

    40% {
        transform: translate(65%, 20%) rotate(-50deg) translateX(15%);
        animation-timing-function: cubic-bezier(0, 0.24, 1, 0.62)
    }

    60% {
        transform: translate(60%, -40%) rotate(-50deg) translateX(20%);
        animation-timing-function: cubic-bezier(0, 0.24, 1, 0.62)
    }

    70% {
        transform: translate(70%, -0%) rotate(-180deg) translateX(20%);
        animation-timing-function: cubic-bezier(0.15, 0.48, 0.76, 0.26)
    }

    100% {
        transform: translate(70%, -0%) rotate(-360deg) translateX(0%) rotate(180deg) translateX(20%);
    }
}

.burst {
    stroke-width: 2;
}

.burst :nth-child(2n) {
    color: #ff783e
}

.burst :nth-child(3n) {
    color: #ffab00
}

.burst :nth-child(4n) {
    color: #55e214
}

.burst :nth-child(5n) {
    color: #82d9f5
}

.circle {
    r: 6;
}

.rect {
    width: 10px;
    height: 10px;
}

.triangle {
    d: path("M0,-6 L7,6 L-7,6 Z");
    stroke-linejoin: round;
}

.plus {
    d: path("M0,-5 L0,5 M-5,0L 5,0");
    stroke-linecap: round;
}

.burst:nth-child(4) {
    transform: translate(30px, 100%) rotate(150deg);
}

.burst:nth-child(5) {
    transform: translate(50%, 0%) rotate(-20deg);
}

.burst:nth-child(6) {
    transform: translate(100%, 50%) rotate(75deg);
}

.burst * {}

@keyframes particle-fade {

    0%,
    100% {
        opacity: 0
    }

    5%,
    80% {
        opacity: 1
    }
}

.burst :nth-child(1) {
    animation: particle-fade 600ms 2.95s both, particle-one-move 600ms 2.95s both;
}

.burst :nth-child(2) {
    animation: particle-fade 600ms 2.95s both, particle-two-move 600ms 2.95s both;
}

.burst :nth-child(3) {
    animation: particle-fade 600ms 2.95s both, particle-three-move 600ms 2.95s both;
}

.burst :nth-child(4) {
    animation: particle-fade 600ms 2.95s both, particle-four-move 600ms 2.95s both;
}

.burst :nth-child(5) {
    animation: particle-fade 600ms 2.95s both, particle-five-move 600ms 2.95s both;
}

.burst :nth-child(6) {
    animation: particle-fade 600ms 2.95s both, particle-six-move 600ms 2.95s both;
}

@keyframes particle-one-move {
    0% {
        transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001)
    }

    100% {
        transform: rotate(-20deg) translateX(8%) scale(0.5, 0.5)
    }
}

@keyframes particle-two-move {
    0% {
        transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001)
    }

    100% {
        transform: rotate(0deg) translateX(8%) scale(0.5, 0.5)
    }
}

@keyframes particle-three-move {
    0% {
        transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001)
    }

    100% {
        transform: rotate(20deg) translateX(8%) scale(0.5, 0.5)
    }
}

@keyframes particle-four-move {
    0% {
        transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001)
    }

    100% {
        transform: rotate(-35deg) translateX(12%)
    }
}

@keyframes particle-five-move {
    0% {
        transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001)
    }

    100% {
        transform: rotate(0deg) translateX(12%)
    }
}

@keyframes particle-six-move {
    0% {
        transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001)
    }

    100% {
        transform: rotate(35deg) translateX(12%)
    }
}

.bar {
    width: 100%;
    height: 100%;
    ry: 50%;
    stroke-width: 2;
    animation: bar-in 900ms 3s both;
}

@keyframes bar-in {
    0% {
        stroke-dasharray: 0 180 0 226 0 405 0 0
    }

    100% {
        stroke-dasharray: 0 0 181 0 227 0 405 0
    }
}

.magnifier {
    animation: magnifier-in 600ms 3.6s both;
    transform-box: fill-box;
}

@keyframes magnifier-in {
    0% {
        transform: translate(20px, 8px) rotate(-45deg) scale(0.01, 0.01);
    }

    50% {
        transform: translate(-4px, 8px) rotate(-45deg);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

.magnifier .glass {
    cx: 27;
    cy: 27;
    r: 8;
    stroke-width: 2;
}

.magnifier .handle {
    x1: 32;
    y1: 32;
    x2: 44;
    y2: 44;
    stroke-width: 2;
}

.search_animation {
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    border-radius: 100px;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}