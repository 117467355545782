
:root {
    // COLORS
    --brown: #52312c;
    --cream: #e8bf81;
    --dark: #202124;
    --dark-01: #3e3f43;
    --white: #ffffff;
    --grey: #c2c2c2;
  
    --red: #c0392b;
    --green: #2ecc71;
  
    // FONT-FAMILY
    --fam-Roboto: "Roboto", sans-serif;
    --fam-Raleway: "Raleway", sans-serif;
    --fam-Barlow: "Barlow Semi Condensed", sans-serif;
    --fam-Sans: "Open-Sans", sans-serif;
    --fam-Poppins: "Poppins", sans-serif;
    --fam-Ubuntu: "Ubuntu", sans-serif;
  
    // SIZES
    --px10: 0.625rem;
    --px13: 0.8125rem;
    --px14: 0.875rem;
    --px15: 0.9375rem;
    --px16: 1rem;
    --px18: 1.125rem;
    --px20: 1.25rem;
    --px22: 1.375rem;
    --px25: 1.5625rem;
    --px30: 1.875rem;
    --px31: 1.9375rem;
    --px35: 2.1875rem;
    --px40: 2.5rem;
    --px50: 3.125rem;
    --px62: 3.875rem;
    --px70: 4.375rem;
    --px80: 5rem;
    --px100: 6.25rem;
    --px1500: 93.75rem;
  
    // FONT-WEIGHT
    --weight300: 300;
    --weight400: 400;
    --weight500: 500;
    --weight600: 600;
    --weight700: 700;
  }
  