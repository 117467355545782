.products {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.products_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 50px;

    @media screen and (max-width: 1700px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 1300px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 870px) {
        grid-template-columns: 1fr;
    }
}

.products_title {
    font-size: 40px;
    line-height: 40px;
    margin-top: 40px;
    font-weight: 300;
    margin-bottom: 30px;

    @media screen and (max-width: 450px) {
        font-size: 38px;
    }

    @media screen and (max-width: 400px) {
        font-size: 32px;
    }

    @media screen and (max-width: 350px) {
        font-size: 26px;
    }
}

.products_categories {
    display: flex;
    align-items: center;
    width: fit-content;

    @media screen and (max-width: 570px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
        column-gap: 20px;
    }
}

.products_category_title {
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    display: flex;

    @media screen and (max-width: 800px) {
        display: none;
    }
}

.products_category {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    margin: 0 4px;
    border: 1px solid var(--dark);
    border-radius: 40px;
    cursor: pointer;

    @media screen and (max-width: 570px) {
        margin: 0;
    }
}

.products_category:hover {
    background-color: #ff9a00;
    box-shadow: inset 0 0 0 #ff9a0066, 0 0 1.5em #ff9a0099;
}

.products_category_selected {
    background-color: #ff9a00;
}

.product_category_image {
    margin-right: 6px;

    @media screen and (max-width: 400px) {
        width: 26px;
    }

    @media screen and (max-width: 330px) {
        width: 24px;
    }
}

.product_category_name {
    margin: 0;
    font-size: 15px;
    font-weight: 300;

    // @media screen and (max-width: 460px) {
    //     font-size: 14px;
    // }

    @media screen and (max-width: 400px) {
        font-size: 12px;
    }
}