.page {
    // background-color: var(--cream);
    // height: calc(100vh - 70px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: 800;
    margin-top: 50px;
    // background: linear-gradient(180deg, var(--brown) 0%, var(--cream) 100%);
    // background: linear-gradient(180deg, rgba(82, 49, 44, 0.98) 0%, rgba(232, 191, 129, 0.98) 50%, rgba(249, 235, 224, 0.98) 100%);
    // ,url(../content/bakery-bg.jpg);
    // background-position: center;
    // background-size: cover;
}