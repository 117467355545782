@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800;900&display=swap');

.card {
    width: 360px;
    // height: 424px;
    height: fit-content;
    margin-bottom: 20px;

    @media screen and (max-width: 450px) {
        // multiply size with 0.75
        width: 270px;
        // height: 318px;
        height: fit-content;
    }

    @media screen and (max-width: 330px) {
        // multiply size with 0.5
        width: 180px;
        // height: 212px;
        height: fit-content;
    }
}

.card_content {
    height: calc(100% - 24px);
    border-radius: 15px;
    overflow: hidden;
    padding: 0 0 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, .25);
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all 0.1s cubic-bezier(0.25, 0.45, 0.45, 0.95);

    &:hover,
    &:focus-within {
        transform: scale(1.01);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.57);
        transition: all 0.3s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }

    @media screen and (max-width: 450px) {
        // multiply size with 0.75
        padding: 0 0 15px;
    }

    @media screen and (max-width: 330px) {
        // multiply size with 0.5
        padding: 0 0 10px;
    }
}

.card_header {
    position: relative;
    width: 360px;
    height: 270px;
    border-radius: 15px;
    overflow: hidden;

    @media screen and (max-width: 450px) {
        // multiply size with 0.75
        width: 270px;
        height: 202.5px;
    }

    @media screen and (max-width: 330px) {
        // multiply size with 0.5
        width: 180px;
        height: 135px;
    }
}

.card_image {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.card_image_no_image {
    width: 128px !important;
    height: 128px !important;
    margin: 71px auto;

    @media screen and (max-width: 450px) {
        margin: 37.25px auto;
    }

    @media screen and (max-width: 330px) {
        width: 64px !important;
        height: 64px !important;
        margin: 35.5px auto;
    }
}

.card_body {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 450px) {
        // multiply size with 0.75
        margin-top: 1.125rem;
    }

    @media screen and (max-width: 330px) {
        // multiply size with 0.5
        margin-top: 0.75rem;
    }
}

.card_product_name {
    width: 100%;
    font-weight: 300;
    font-size: 1.375rem;
    line-height: 1.25;
    text-decoration: none;
    color: inherit;
    will-change: transform;
    padding: 0 10px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 450px) {
        // multiply size with 0.75
        font-size: 1.03125rem;
    }

    @media screen and (max-width: 330px) {
        // multiply size with 0.5
        font-size: 0.6875rem;
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

.icon_button {
    border: 0;
    background-color: #fff;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: 1.25rem;
    transition: .25s ease;
    box-shadow: 0 0 0 1px rgba(#000, .05), 0 3px 8px 0 rgba(#000, .15);
    z-index: 1;
    cursor: pointer;
    color: #565656;

    &:hover,
    &:focus {
        background-color: #EC4646;
        color: #FFF;
    }
}

.card_footer {
    margin-top: 20px;
    border-top: 1px solid #888;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    @media screen and (max-width: 450px) {
        // multiply size with 0.75
        padding-top: 0.9375rem;
        margin-top: 0.9375rem;
    }

    @media screen and (max-width: 330px) {
        // multiply size with 0.5
        padding-top: 0.6255rem;
        margin-top: 0.6255rem;
    }
}

.card_meta {
    display: flex;
    align-items: center;
    color: #333;
    font-weight: 400;
    font-size: 24px;

    @media screen and (max-width: 450px) {
        // multiply size with 0.75
        font-size: 18px;
    }

    @media screen and (max-width: 330px) {
        // multiply size with 0.5
        font-size: 12px;
    }
}

.card_spinner_container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_spinner_container>* {
    transform: scale(0.5);
}