.carousel_button {
    position: absolute;
    top: calc((100% - 24px)/2);
    opacity: 0.8;
    transition: 0.5s all ease-in-out;
}

.carousel_button:hover {
    opacity: 1;
}

.next_button {
    right: 8px;
}

.previous_button {
    left: 8px;
}