.product_detail_container {
    height: calc(100vh - 50px);
    min-height: 850px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 700px) {
        min-height: 1300px;
    }

    @media screen and (max-width: 600px) {
        min-height: 1220px;
    }

    @media screen and (max-width: 500px) {
        min-height: 1130px;
    }

    @media screen and (max-width: 450px) {
        min-height: 1070px;
    }

    @media screen and (max-width: 400px) {
        min-height: 1000px;
    }

    @media screen and (max-width: 350px) {
        min-height: 950px;
    }

    @media screen and (max-width: 300px) {
        min-height: 900px;
    }

    @media screen and (max-width: 250px) {
        min-height: 850px;
    }
}

.product_detail {
    border-top-right-radius: 24px;
    position: relative;
    height: 675px;
    width: 1100px;
    border-radius: 15px;
    display: flex;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, .25);
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all 0.1s cubic-bezier(0.25, 0.45, 0.45, 0.95);

    @media screen and (max-width: 1200px) {
        width: 900px;
    }

    @media screen and (max-width: 1000px) {
        width: 800px;
    }

    @media screen and (max-width: 900px) {
        width: 700px;
        height: 540px;
    }

    @media screen and (max-width: 800px) {
        width: 600px;
        height: 450px;
    }

    @media screen and (max-width: 700px) {
        width: 86%;
        flex-direction: column;
        height: fit-content;
    }

}

.product_detail_image_container {
    width: 450px;
    position: relative;

    @media screen and (max-width: 700px) {
        width: 100%;
    }
}

.product_detail_image {
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 15px;

    @media screen and (max-width: 700px) {
        width: 100%;
    }
}

.product_detail_no_image {
    height: 100% !important;
    width: 100% !important;
    object-fit: none !important;
    object-position: center !important;
}

.product_detail_spinner_container {
    position: absolute;
    // width: 100%;
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.5);

}

.product_detail_content {
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 30px;

    @media screen and (max-width: 700px) {
        margin: 30px auto;
        width: calc(100% - 20px);
        padding: 0 10px;
    }

    @media screen and (max-width: 600px) {
        margin: 20px auto;
    }

    @media screen and (max-width: 500px) {
        margin: 10px auto;
    }
}

.product_detail_header {
    width: 100%;
    margin-top: 16px;
}

.product_detail_product_name {
    width: 100%;
    font-weight: 300;
    font-size: 2.3rem;
    line-height: 1.25;
    text-decoration: none;
    color: inherit;
    will-change: transform;

    @media screen and (max-width: 1200px) {
        font-size: 28px;
    }

    @media screen and (max-width: 1000px) {
        font-weight: 400;
        font-size: 24px
    }

    @media screen and (max-width: 900px) {
        font-size: 18px;
    }

    @media screen and (max-width: 700px) {
        font-size: 24px;
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

.product_detail_body {
    font-size: 20px;
    width: 100%;
    margin-top: 12px;
}

.product_ingredients_container {
    text-align: center;
    padding: 0 16px;

    @media screen and (max-width: 700px) {
        margin-top: 20px;
    }
}

.product_ingredients_title {
    font-weight: 400;
    margin: 0;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 10px;

    @media screen and (max-width: 900px) {
        font-size: 20px;
        line-height: 22px;
    }

    @media screen and (max-width: 800px) {
        font-size: 18px;
        line-height: 20px;
    }

    @media screen and (max-width: 700px) {
        font-size: 22px;
        line-height: 24px;
    }

    @media screen and (max-width: 500px) {
        font-size: 20px;
        line-height: 22px;
    }
}

.product_ingredients_detail {
    font-weight: 300;
    font-size: 20px;
    line-height: 22px;
    margin: 0;
    margin-bottom: 10px;

    @media screen and (max-width: 900px) {
        font-size: 18px;
        line-height: 22px;
    }

    @media screen and (max-width: 800px) {
        font-size: 16px;
        line-height: 20px;
    }

    @media screen and (max-width: 700px) {
        font-size: 20px;
        line-height: 22px;
    }

    @media screen and (max-width: 500px) {
        font-size: 18px;
        line-height: 20px;
    }
}

.product_detail_footer {
    width: 100%;
    margin-top: 1.25rem;
    border-top: 1px solid #888;
    padding-top: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.product_detail_meta {
    display: flex;
    align-items: center;
    color: #333;
    font-weight: 400;
    font-size: 24px;
}

.x_close {
    z-index: 1599;
    position: absolute;
    top: 5px;
    right: 5px;
    height: 30px;
    width: 30px;
    border-radius: 8px;
    // background-color: #bfa3884c;
    background-color: #f86059cc;

    @media screen and (max-width: 800px) {
        height: 24px;
        width: 24px;
        border-radius: 6px;
    }

    @media screen and (max-width: 700px) {
        top: 10px;
        right: 10px;
    }

    @media screen and (max-width: 500px) {
        height: 20px;
        width: 20px;
        border-radius: 4px;
    }
}

.x_close:hover {
    .x_line {
        stroke-width: 9px;
        transition: all 0.1s ease-in-out;
    }
}

.x_line {
    fill: none;
    stroke: var(--dark);
    stroke-width: 6px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transform-origin: 50%;
    transition: all 0.1s ease-in-out;
}

.product_detail_list {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7px;
    width: 100px;
    bottom: 15px;
    left: calc((100% - 100px)/2);
}

.product_detail_item {
    display: flex;
    height: 7px;
    width: 7px;
    margin: 0 4px;
    border-radius: 100px;
    background: #ffffff;
    opacity: 0.6;
}

.item_active {
    opacity: 1;
}