.about {
    width: 80%;
    margin-bottom: 50px;
}

.about_header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about_title {
    font-size: 40px;
    line-height: 40px;
    margin-top: 40px;
    font-weight: 300;
    margin-bottom: 30px;
    width: 500px;
}

.about_subtitle {
    font-size: 20px;
    line-height: 20px;
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 45px;
    color: #444;
}

.about_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.about_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.about_img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.about_location {
    background-image: url('../../content/location.png');
    height: 656.25px;
    width: 425.95px;

    @media screen and (max-width: 450px) {
        height: 590.63px;
        width: 383.36px;
    }

    @media screen and (max-width: 400px) {
        height: 472.5px;
        width: 306.68px;
    }

    @media screen and (max-width: 320px) {
        height: 378px;
        width: 245.34px;
    }
}

.about_windmill {
    background-image: url('../../content/windmill.png');
    height: 360px;
    width: 360px;

    @media screen and (max-width: 370px) {
        height: 288px;
        width: 288px;
    }

    @media screen and (max-width: 320px) {
        height: 230px;
        width: 230px;
    }
}

.about_description {
    width: 60%;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    margin: auto 0;

    @media screen and (max-width: 1000px) {
        font-size: 16px;
        line-height: 22px;
    }

    @media screen and (max-width: 320px) {
        font-size: 14px;
        line-height: 20px;
    }
}

.about_description_location {
    text-align: justify;
    margin-left: 50px;

    @media screen and (max-width: 800px) {
        margin: 30px auto 0;
        width: 100%;
        text-align: center;
    }
}

.about_description_windmill {
    text-align: left;

    @media screen and (max-width: 700px) {
        margin: 30px auto;
        width: 100%;
        text-align: center;
    }
}

.about_bottom {
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
}

.about_top {
    @media screen and (max-width: 700px) {
        flex-direction: column-reverse;
    }
}