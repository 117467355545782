.nav {
    position: fixed;
    top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    z-index: 1600;

    @media screen and (max-width: 700px) {
        height: 26px;
    }
}

.nav_container {
    height: 100%;
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--dark);
    padding: 15px 20px;
    border-radius: 50px;
    box-shadow: 0px 0px 10px #f5f5dc5c;
}

.nav_link_container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 630px) {
        display: none;
    }
}

.nav_link {
    margin: 0 30px;
    text-decoration: none;
    font-weight: var(--weight300);
    color: var(--cream);
    letter-spacing: .1rem;
    font-size: 20px;
    line-height: 30px;

    @media screen and (max-width: 820px) {
        margin: 0 28px;
    }

    @media screen and (max-width: 790px) {
        font-size: 18px;
    }

    @media screen and (max-width: 750px) {
        font-size: 16px;
        margin: 0 26px;
    }

    @media screen and (max-width: 700px) {
        font-size: 15px;
        margin: 0 24px;
    }

    @media screen and (max-width: 650px) {
        font-size: 14px;
        margin: 0 22px;
    }
}

.nav_link:hover {
    opacity: 0.8;
}

.nav_logo {
    height: 40px;

    @media screen and (max-width: 700px) {
        height: 38px;
    }
}

.nav_settings {
    cursor: pointer;
    line-height: 30px;
    font-size: 20px;
    color: var(--cream);
}

.nav_menu {
    position: absolute;
    top: 37px;
    width: 88%;
    background-color: var(--dark);
    transition: 0.3s all ease-in-out;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-top: 3px;

    @media screen and (min-width: 630px) {
        height: 0 !important;
    }
}

.menu_link {
    padding: 30px 0;
    font-size: 25px;
    width: 50%;
    transition: 0.3s all ease-in-out;

    @media screen and (max-width: 500px) {
        font-size: 23px;
    }

    @media screen and (max-width: 450px) {
        font-size: 21px;
    }

    @media screen and (max-width: 380px) {
        font-size: 19px;
    }

    @media screen and (max-width: 300px) {
        font-size: 15px;
    }
}

.menu_link:hover {
    width: 100%;
    background-color: var(--dark-01);
    transition: 0.3s all ease-in-out;
}