.home {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 76%;
    height: 100%;
    margin-top: 20px;
    height: calc(100vh - 70px);
    min-height: 600px;

    @media screen and (max-width: 1650px) {
        width: 80%;
    }

    @media screen and (max-width: 1600px) {
        width: 85%;
    }

    @media screen and (max-width: 1450px) {
        width: 90%;
    }

    @media screen and (max-width: 1200px) {
        min-height: 500px;
    }

    @media screen and (max-width: 850px) {
        justify-content: center;
        min-height: 400px;
    }

    @media screen and (max-width: 600px) {
        min-height: 350px;
    }
}

.home_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    width: 60%;

    @media screen and (max-width: 850px) {
        align-items: center;
        width: 90%;
    }
}

.home_title {
    display: flex;
    text-align: left;
    font-weight: 300;
    letter-spacing: 5px;
    margin-top: 220px;
    font-size: 84px;
    width: 500px;
    line-height: 78px;
    margin-bottom: 30px;
}

.home_subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 12px;
    color: red;
}

.home_subtitle_container {
    display: flex;
    flex-direction: column;
    line-height: 18px;
    margin: 44px -10px 0;
}

.home_short_description {
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    color: #444;
    width: 100%;
    text-align: left;
    margin-top: 18px;
    line-height: 20px;
    margin-bottom: 30px;
    letter-spacing: 2px;
    text-wrap: balance;

    @media screen and (max-width: 850px) {
        text-align: center;
    }
}

.home_croissant_container {
    width: 40%;
    transform: scale(0.85);
    display: flex;

    @media screen and (max-width: 850px) {
        display: none;
    }
}

.home_croissant {
    width: 100%;
    height: 100%;
}

.home_link {
    padding: 8px 16px;
    border: 3px solid black;
    border-radius: 50px;
    color: black;
    text-decoration: none;
    font-size: 22px;
    border-end-start-radius: 0;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_link:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.05);
    background-color: rgba(0, 0, 0, 0.1);
}

.delicii {

    @media screen and (max-width: 1370px) {
        width: 100%;
    }
}

.croissant {

    @media screen and (max-width: 1370px) {
        width: 100%;
    }
}

.fire {
    width: 26px;
    height: 26px;
}