.leaflet-container {
    height: 400px;
}

.popup_container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup_title {
    margin: 0;
    font-weight: 200;
}