@import url('https://fonts.googleapis.com/css?family=Noto+Sans');

.side_bar_overlay {
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #00000080;
    width: 100vw;
    height: 100vh;
    z-index: 1700;
    // overflow: scroll;
}

.side_bar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    // background-color: #000;
    background-color: var(--dark);
    width: 400px;
    height: 100vh;
    min-height: 500px;
    z-index: 2000;
    // overflow: scroll;

    @media screen and (max-width: 430px) {
        width: 100vw;
    }
}

.side_bar_button_container {
    display: flex;
    justify-content: flex-end;
    margin: 24px 24px 0 0;
}

.container {
    position: relative;
    width: 2em;
    height: 2em;
}

.container:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0;
    width: 0;
    border: 1em solid rgba(100, 100, 100, 0.8);
    border-radius: 6em;
    transition: all 0.2s ease-in-out;
}

.close_icon {
    transition: all 0.075s ease-in-out;
    position: absolute;
    width: 2em;
    height: 2em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0.1em solid;
    border-color: rgba(250, 250, 250, 0.8);
    border-radius: 6em;
}

.close_icon:after {
    transition: all 0.075s ease-in-out;
    font: 1em Helvetica, arial;
    font-size: 1em;
    width: 100%;
    height: 100%;
    content: "x";
    color: rgba(250, 250, 250, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, -5%) scale(1.25, 1);
}


.container.zoom_shrink:hover .close_icon.zoom_shrink {
    font-size: 1.2em;
}

.container.zoom_shrink:active:before {
    // border-color: #c86464cc;
    border-color: #c0392bcc;
}

.container.zoom_shrink:active .close_icon.zoom_shrink {
    font-size: 0.75em;
    border-color: rgba(50, 50, 50, 0.8);
}

.container.zoom_shrink:active .close_icon.zoom_shrink:after {
    color: rgba(50, 50, 50, 0.8);
}


.side_bar_language {
    width: 50px;
    height: 50px;
    transform: scale(1.2);
    opacity: 0.1;
    cursor: pointer;
}

.side_bar_language_selected_img {
    opacity: 1 !important;
}

.side_bar_language:hover {
    opacity: 1;
}

.side_bar_language_container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    margin-bottom: 50px;
}

.side_bar_language_button {
    display: flex;
    position: relative;
    width: 50px;
    height: 50px;
    background: linear-gradient(to bottom, gray 0%, dimgray 100%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .3);
    border-radius: 50%;
    line-height: 100px;
    text-align: center;
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.side_bar_language_button::before {
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: black;
    opacity: 0;
    content: '';
}

.side_bar_language_button:hover {
    &::before {
        animation: pulse 1s infinite;
    }

    background: linear-gradient(to bottom, hotpink 0%, tomato 100%);
}

.side_bar_language_selected.side_bar_language_selected {
    background: linear-gradient(to bottom, hotpink 0%, tomato 100%);
}

.side_bar_language_selected.side_bar_language_selected:before {
    animation: blastOut 1s;
}

@keyframes pulse {
    from {
        transform: scale(1);
        opacity: .4;
    }

    to {
        transform: scale(1.3);
        opacity: 0;
    }
}

@keyframes blastOut {
    from {
        transform: scale(0.9);
        opacity: .4;
    }

    to {
        transform: scale(100);
        opacity: 0;
    }
}

.side_bar_instagram {
    background-color: #3e3e3e;
    padding: 6px 12px;
    border-radius: 70px;
    margin: 40px 12px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 320px) {
        // transform: scale(0.9);
        width: 90%;
        margin: 40px auto 20px;
    }

    @media screen and (max-width: 300px) {
        transform: scale(0.9);
    }
}

.side_bar_title {
    margin: 0 30px;
    text-decoration: none;
    font-weight: var(--weight300);
    color: var(--cream);
    letter-spacing: .1rem;
    font-size: 20px;
    line-height: 30px;

    @media screen and (max-width: 350px) {
        margin-top: 20px;
    }

    @media screen and (max-width: 300px) {
        font-size: 16px;
    }

    @media screen and (max-width: 270px) {
        font-size: 15px;
    }
}

.side_bar_logo {
    height: 70px;
    width: 70px;
    border-radius: 70px;
}

.side_bar_info {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.side_bar_name {
    color: #fff;
    margin: 0;
    padding-bottom: 4px;
}

.side_bar_description {
    color: var(--cream);
    margin: 0;
    opacity: 0.8;
    display: flex;

    @media screen and (max-width: 270px) {
        display: none;
    }
}

.side_bar_button {
    background-color: rgb(0, 149, 246);
    color: #fff;
    width: fit-content;
    margin: 6px auto 0;
    padding: 7px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.side_bar_button:hover {
    background-color: rgb(24, 119, 242);
    transform: scale(1.05);
}

.side_bar_user {
    margin-right: 6px;
}

.side_bar_follow {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}