.rocket {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 1900;
    cursor: pointer;


    @media screen and (max-width: 1000px) {
        right: 25px;
        bottom: 25px;
        transform: scale(0.9);
    }

    @media screen and (max-width: 500px) {
        right: 20px;
        bottom: 20px;
        transform: scale(0.7);
    }
}

.rocket_lift_off {
    position: fixed;
    right: 30px;
    bottom: 1200px !important;
    z-index: 1900;
    cursor: pointer;
    transition: 0.5s bottom ease-in;

    @media screen and (max-width: 1000px) {
        right: 25px;
        transform: scale(0.9);
    }

    @media screen and (max-width: 500px) {
        right: 20px;
        transform: scale(0.7);
    }
}

.hide {
    display: none;
}