.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

.website_back {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-color: var(--cream);
  background: linear-gradient(180deg,
      rgba(82, 49, 44, 0.98) 0%,
      rgba(232, 191, 129, 0.98) 50%,
      rgba(249, 235, 224, 0.98) 100%), url(./content/bakery-bg.jpg);
      
  background-position: center;
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}