.contact {
    display: flex;
    flex-direction: column;
    width: 80%;

    @media screen and (max-width: 600px) {
        width: 90%;
    }
}

.contact_header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact_title {
    font-size: 40px;
    line-height: 40px;
    margin-top: 40px;
    font-weight: 300;
    margin-bottom: 30px;
    width: 500px;

    @media screen and (max-width: 550px) {
        font-size: 36px;
        width: 100%;
    }

    @media screen and (max-width: 500px) {
        font-size: 32px;
    }
}

.contact_subtitle {
    font-size: 20px;
    line-height: 20px;
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 45px;
    color: #444;

    @media screen and (max-width: 550px) {
        font-size: 18px;
    }

    @media screen and (max-width: 500px) {
        font-size: 16px;
    }
}

.contact_program {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact_program_title {
    font-size: 28px;
    line-height: 26px;
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 30px;

    @media screen and (max-width: 500px) {
        font-size: 24px;
        font-weight: 300;
    }
}

.contact_program_content {
    background-color: #faebd742;
    width: 320px;
    padding: 14px 8px;
    border-radius: 16px;
    margin-bottom: 25px;

    @media screen and (max-width: 500px) {
        width: 260px;
        padding: 12px 6px;
        border-radius: 12px;
    }
}

.contact_program_days {
    font-size: 20px;
    line-height: 20px;
    margin-top: 0px;
    font-weight: 600;
    margin-bottom: 0px;

    @media screen and (max-width: 500px) {
        font-size: 18px;
        line-height: 20px;
        font-weight: 500;
    }
}

.contact_program_hours {
    font-size: 26px;
    line-height: 40px;
    margin-top: 14px;
    font-weight: 300;
    margin-bottom: 45px;

    @media screen and (max-width: 500px) {
        font-size: 22px;
        line-height: 32px;
    }
}

.contact_info {
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    width: 80%;

    @media screen and (max-width: 1500px) {
        width: 100%;
    }

    @media screen and (max-width: 460px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.contact_info_container:nth-child(2) {
    margin: 0 6px;

    @media screen and (max-width: 460px) {
        margin: 6px 0;
    }
}

.contact_info_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    cursor: pointer;
    padding: 14px 8px;
    max-width: 284px;

    @media screen and (max-width: 1200px) {
        justify-content: space-between;
    }

    @media screen and (max-width: 460px) {
        width: 50%;
    }

    @media screen and (max-width: 400px) {
        width: 60%;
    }
}

.contact_info_container:hover {
    background-color: antiquewhite;
    border-radius: 8px;
    cursor: pointer;
}

.contact_icons {
    color: var(--dark);

    @media screen and (max-width: 800px) {
        font-size: 28px;
    }

    @media screen and (max-width: 700px) {
        font-size: 24px;
    }

    @media screen and (max-width: 550px) {
        font-size: 20px;
    }

    @media screen and (max-width: 460px) {
        font-size: 28px;
    }
}

.contact_info_detail {
    font-size: 14px;
    line-height: 20px;
    margin: 14px 0 0;
    font-weight: 400;
    color: #222;

    @media screen and (max-width: 1121px) {
        height: 40px;
    }

    @media screen and (max-width: 800px) {
        font-size: 12px;
    }

    @media screen and (max-width: 700px) {
        font-size: 11px;
    }

    @media screen and (max-width: 550px) {
        font-size: 10px;
    }

    @media screen and (max-width: 460px) {
        font-size: 14px;
    }
}

.contact_map {
    margin: 20px 0;
}