.menu_svg {
    height: 50px;
    position: absolute;
    width: 50px;
}

.plate {
    width: 40px;
    display: none;
    cursor: pointer;

    @media screen and (max-width: 630px) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.x {
    transform: scale(0);
    transition: transform 400ms;
}

.line {
    fill: none;
    stroke: var(--cream);
    stroke-width: 6px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transform-origin: 50%;
    transition: stroke-dasharray 500ms 200ms, stroke-dashoffset 500ms 200ms, transform 500ms 200ms;
}

.x .line {
    stroke-width: 5.5px;
}

.active .line {
    transition: stroke-dasharray 500ms, stroke-dashoffset 500ms, transform 500ms;
}

.active .x {
    transform: scale(1);
    transition: transform 400ms 350ms;
}


.plate_content .line {
    transition: stroke-dasharray 400ms 100ms, stroke-dashoffset 400ms 100ms, transform 400ms 100ms;
}

.plate_content .line1 {
    stroke-dasharray: 21 102;
}

.plate_content .line2 {
    stroke-dasharray: 21 92;
}

.plate_content .line3 {
    stroke-dasharray: 21 97;
}

.plate_content .line4 {
    stroke-dasharray: 21 97;
}

.plate_content .line5 {
    stroke-dasharray: 21 92;
}

.plate_content .line6 {
    stroke-dasharray: 21 102;
}

.plate_content .x {
    transition: transform 400ms 50ms;
}

.active.plate_content .line {
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms, transform 400ms;
}

.active.plate_content .line1 {
    stroke-dasharray: 2 102;
    stroke-dashoffset: -102px;
}

.active.plate_content .line2 {
    stroke-dasharray: 2 92;
    stroke-dashoffset: -92px;
}

.active.plate_content .line3 {
    stroke-dasharray: 2 97;
    stroke-dashoffset: -97px;
    transition-delay: 20ms;
}

.active.plate_content .line4 {
    stroke-dasharray: 2 97;
    stroke-dashoffset: -97px;
    transition-delay: 20ms;
}

.active.plate_content .line5 {
    stroke-dasharray: 2 92;
    stroke-dashoffset: -90px;
    transition-delay: 40ms;
}

.active.plate_content .line6 {
    stroke-dasharray: 2 102;
    stroke-dashoffset: -100px;
    transition-delay: 40ms;
}

.active.plate_content .x {
    transition: transform 400ms 50ms;
}